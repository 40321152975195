import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-archived',
  templateUrl: './archived.component.html',
  styleUrls: [
    './archived.component.scss',
    './../all-invoices/all-invoices.component.scss',
  ],
})
export class ArchivedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
