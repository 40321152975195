import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-system-charts',
  templateUrl: './system-charts.component.html',
  styleUrls: ['./system-charts.component.scss']
})
export class SystemChartsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
