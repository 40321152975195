import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/dataStore/data.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'mapped-grn',
  templateUrl: './mapped-grn.component.html',
  styleUrls: ['./mapped-grn.component.scss','../../invoice/view-invoice/view-invoice.component.scss']
})
export class MappedGRNComponent implements OnInit {
  grnTabDatalength:number;
  GRNTabData: any;
  currentlyOpenedItemIndex = -1;
  isDesktop: boolean;
  linedata_mobile = [];

  constructor(private SharedService: SharedService,
    private ds :DataService) { }

  ngOnInit(): void {
    this.getGRNtabData();
    this.isDesktop = this.ds.isDesktop;
  }
  getGRNtabData() {
    this.SharedService.getGRNTabData().subscribe((data: any) => {
      this.GRNTabData = data?.result;
      this.grnTabDatalength = Object.keys(this.GRNTabData).length;
      if(!this.isDesktop){
        for(let i in this.GRNTabData){
          let lineGRN = [];
        // Get the maximum number of linedata entries across all tags
        const maxLinedataEntries = Math.max(...this.GRNTabData[i].map(tag => tag.linedata.length));
        // Iterate through the index of linedata entries
        for (let dataIndex = 0; dataIndex < maxLinedataEntries; dataIndex++) {
          const transformedData:any = [];

          // Iterate through the received data
          this.GRNTabData[i].forEach(tag => {
            const tagName = tag.TagName;
            const linedata = tag.linedata[dataIndex];
            const itemData = linedata.DocumentLineItems;

            // Create an object with the TagName and linedata for the current index
            const tagObject = {
              TagName: tagName,
              linedata: linedata
            };

            // Add the tagObject to the transformedData array
            transformedData.push(tagObject);
          });
          // Add the transformedData array for the current index to the main array
          
          lineGRN.push(transformedData);
        }
        this.linedata_mobile[i] = lineGRN;
        }
        
      }
    })
  }

  
  setOpened(itemIndex) {
    this.currentlyOpenedItemIndex = itemIndex;
  }

  setClosed(itemIndex) {
    if (this.currentlyOpenedItemIndex === itemIndex) {
      this.currentlyOpenedItemIndex = -1;
    }
  }
}
