<div *ngIf="grnTabDatalength>0">
    <mat-accordion>
      <div class="row"  *ngFor="let grnitem of GRNTabData | keyvalue; let i = index;">
        <div class="col-sm-12">
          <mat-expansion-panel 
          [expanded]="true"
            (opened)="setOpened(i)"
            (closed)="setClosed(i)" style="margin-top: 10px;">
            
            <mat-expansion-panel-header class="KPIName">
              <mat-panel-title class=" secondheadertoggle">
                
                <h6 class="kpi-header f-13 faPulsandMinus head-pos">
                  {{ grnitem.key }}
                </h6>
              
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container>
            <table class="invert" style="width: 100%">
              <tr
                class="invert_tr bg-wt"
                *ngFor="let item of grnitem.value; let i = index"
              >
                <td
                  class="lineName invert_th_td"
                  [ngStyle]="{
                    'min-width': item.TagName == 'S.No' ? '40px' : '130px'
                  }"
                >
                  {{ item.TagName }}
                </td>
                <!-- <div>
                      <div class="linevalue invert_thtd">{{i+1}}</div>
                    </div> -->
                <td
                  class="linevalue invert_th_td"
                  *ngFor="let line1 of item.linedata; let i = index"
                >
                  <div
                    *ngFor="let line of line1 | keyvalue"
                    class="d-flex"
                    style="position: relative"
                  >
                    <div *ngIf="line.key == 'DocumentLineItems'">
                      <div *ngIf="item.TagName == 'S.No'">
                        <div class="linevalue invert_thtd p-2">
                          {{ line.value.itemCode }}
                        </div>
                      </div>
                      <span
                        *ngIf="
                          item.TagName != 'S.No' && item.TagName != 'Actions'
                        "
                      >
                        <input
                          #inputv
                          type="text"
                          [ngClass]="
                            item.TagName == 'Description' ||
                            item.TagName == 'Name'
                              ? 'w-des'
                              : 'w-sm'
                          "
                          [value]="line.value.Value"
                          [title]="line.value.Value"
                          readonly
                          class="form-control mb-1 inputHeight"
                        />
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            </ng-container>
      
          </mat-expansion-panel>
        </div>
      </div>
      
      
    </mat-accordion>
  </div>

  <!-- <div *ngIf="!isDesktop">
    <div class="example-action-buttons f-12">
      <button mat-button class="f-12" (click)="accordion.openAll()">Expand All</button>
      <button mat-button class="f-12" (click)="accordion.closeAll()">Collapse All</button>
    </div>
    <mat-accordion multi>
      <div *ngFor="let lined of linedata_mobile; let i = index;">
          <mat-expansion-panel 
          [expanded]="true"
            (opened)="setOpened(i)"
            (closed)="setClosed(i)" style="margin-top: 10px;">
            
            <mat-expansion-panel-header class="KPIName" [ngStyle]="{ 'background-color': lined.hasError ? '#fff4d2':lined.hasUpdated? '#d2e8f9':''}">
              <mat-panel-title class=" secondheadertoggle" >
                <h6 class="f-12 line_mob">Line number - {{i+1}}</h6>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container>
              <div class="displayFlex">
                <div *ngFor="let item of lined">
                    <span>
                      <label class="label-head" style="margin-bottom: -5px" > {{item.TagName}}</label>
                      <input
                        #inputv
                        type="text"
                        [ngClass]="
                          item.TagName == 'GRNLineDescription'
                            ? 'w-des'
                            : ''
                        "
                        [value]="item.linedata.DocumentLineItems.Value"
                        [title]="item.linedata.DocumentLineItems.Value"
                        readonly
                        class="form-control mb-1 inputHeight"
                      />
                    </span>
                </div>
              </div>
            </ng-container>
      
          </mat-expansion-panel>
      </div>
    </mat-accordion>
    <div *ngFor="let lined of linedata_mobile; let i = index;">
      <h6 class="f-12 line_mob">Line number - {{ i + 1 }}</h6>

    </div>
  </div> -->
  <div *ngIf="!(grnTabDatalength>0)" class="d-flex justify-content-center f-13 mt-4">
    No GRN is mapped with the Invoice.
  </div>
