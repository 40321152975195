<div>
  <div>
    <div class="searchBar">
      <input
        class="searchInput"
        pInputText
        type="text"
        (keyup)="searchInvoice($event.target.value)"
        (input)="PO.filterGlobal($event.target.value, 'contains')"
        placeholder="Search..."
      />
      <span class="material-icons searchIcon"> search </span>
    </div>
    <p-table
      #PO
      [value]="users"
      [reorderableColumns]="true"
      [globalFilterFields]="columnstodisplayPO"
      [columns]="poColumns"
      [paginator]="showPaginatorPOTable"
      [showFirstLastIcon]="true"
      [rows]="rows"
      [first]="first"
      (onPage)="paginate($event)"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-striped"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            *ngFor="let col of columns"
            [pTooltip]="col.columnDescription"
            tooltipPosition="top"
            [pSortableColumn]="col.dbColumnname"
            pReorderableColumn
          >
            {{ col.columnName }}
          </th>
          <th class="action_Zindex">
            Actions
            <i
              class="fa fa-pencil-square-o actionsBtn"
              style="border: 0"
              aria-hidden="true"
              (click)="showSidebar()"
            ></i>
          </th>
        </tr>
        <tr>
          <td *ngFor="let col of columns">
            <input
              pInputText
              type="text"
              (input)="
                PO.filter(
                  $event.target.value || ' ',
                  col.dbColumnname,
                  'contains'
                )
              "
              [value]="PO.filters[col.dbColumnname]?.value"
              [placeholder]="'Search by ' + col.columnName"
              class="tableSearchInput"
            />

            <!-- <p-columnFilter placeholder="Search by ID" type="text"  [field]="col.field"></p-columnFilter> -->
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-invoiceData let-columns="columns">
        <tr>
          <td
            *ngFor="let col of columns"
            [title]="invoiceData[col.dbColumnname]"
          >
            <div [ngSwitch]="col.dbColumnname">
              <div *ngSwitchCase="'docstatus'">
                <!-- <td style="width: 20%;" class="statusType"
                  [ngStyle]="{'background-color': (invoiceData.docstatus === 'Rejected' ) ? 'red' : (invoiceData.docstatus === 'In Progress' ) ? '#ff9494': (invoiceData.docstatus === 'Wait Approve' ) ? '#37dcc7': (invoiceData.docstatus === 'Approved' ) ? '#b4ea21': (invoiceData.docstatus === 'Financially Approved' ) ? '#2bd43c': (invoiceData.docstatus === 'paid' ) ? '#107e3e': (invoiceData.docstatus === 'Review' ) ? '#e6c213':'' } ">
                  {{invoiceData[col.dbColumnname]}}
                </td> -->
                <div *ngFor="let color of bgColorCode">
                  <td
                    class="statusType"
                    *ngIf="invoiceData.documentStatusID == color.id"
                    [ngStyle]="{
                      'background-color':
                        invoiceData.documentStatusID == color.id
                          ? color.bgcolor
                          : '',
                      color:
                        invoiceData.documentStatusID == color.id
                          ? color.textColor
                          : ''
                    }"
                  >
                    {{ invoiceData[col.dbColumnname] }}
                  </td>
                </div>
              </div>
              <div class="elipse" *ngSwitchDefault>
                {{ invoiceData[col.dbColumnname] }}
              </div>
            </div>
          </td>
          <td>
            <i
              class="pi pi-eye f-16 showEye mr-2"
              (click)="toCreateNew(invoiceData)"
            ></i>
            <!-- <i class="fa fa-pencil-square-o actionsBtn" aria-hidden="true" (click)="editInvoice(invoiceData)"></i> -->
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No PO Data found.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft"> </ng-template>
      <ng-template pTemplate="paginatorright"> </ng-template>
    </p-table>
  </div>
</div>
